

import Environment          from '@/components/environment/Environment.vue';
import EnvironmentClass     from '@/dto/security/Environment.class';
import Password             from './Password.class';
import StorageService       from '@/services/storage/StorageService.class';
import {
    getEnvironment,
    testUrl,
  }                         from '@/http/login/login';
import { StoreKey }         from '@/services/storage/StorageService.interface';
import { RouteName }        from '@/router/Route.interface';

import Vue from 'vue';
export default Vue.extend({
  name: 'InputPassword',
  components: {
    Environment,
  },

  props: {
    model: {
      type: Password,
      required: true,
      default() { return new Password(); }
    },
    title: {
      type: String,
      required: true,
      default() { return 'Welcome'; }
    },
    subtitle: {
      type: String,
      required: true,
      default() { return 'Log into your account'; }
    },
    email: {
      type: Boolean,
      required: true,
      default() { return true; }
    },
    forgot: {
      type: Boolean,
      required: true,
      default() { return true; }
    },
    button: {
      type: String,
      required: true,
      default() { return 'Sign In'; }
    },
    error: {
      type: String,
      required: true,
      default() { return ''; }
    },
  },

  data() {
    return {
      environment: new EnvironmentClass(),
      storage: new StorageService(),
      clientLogoUrl: '',
      show: false,
      inputPasswordClass: '',
    };
  },

  mounted() {
    this.removeStoredClientLogo();
    const myLogin = this.storage.get<string>(StoreKey.USER);
    if (myLogin !== undefined) {
      this.updateEnvironment();
    }
  },

  methods: {

    removeStoredClientLogo() {
      this.storage.remove(StoreKey.CLIENT_LOGO_URL);
      this.storage.remove(StoreKey.USER_PHOTO_URL);
      this.clientLogoUrl = '';
    },

    updateEnvironment() {
      if (!this.model.isValidEmail()) return;
      this.removeStoredClientLogo();
      getEnvironment(this.model.u)
        .then((response: any) => { this.setEnvironment(response); })
        .catch((error: any) => {
          this.setEnvironment(undefined);
        })
        .finally(() => {
          // this.option.isLoading = false;
        });
    },

    setEnvironment(response: any) {
      this.environment = new EnvironmentClass(response);
      this.storage.store(StoreKey.USER, this.model.u);
      this.testUrlOfClientLogo();
    },

    testUrlOfClientLogo() {
      testUrl('logos/' + this.environment.uuid)
        .then((response: any) => { /* do nothing */ })
        .catch((statusAndUrl: any) => {
          this.setClientLogoUrl(statusAndUrl);
        });
    },
    setClientLogoUrl(response: any) {
      if (!response || response.status !== '200') {
        this.clientLogoUrl = '';
        return;
      }
      this.clientLogoUrl = '' + response.url;
      this.storage.store(StoreKey.CLIENT_LOGO_URL, this.clientLogoUrl);
    },

    gotoLogon(): void {
      this.$router.push({ name: RouteName.Logon });
    }
    //
  },
});

