import Lres from '@/dto/security/Lres.interface';

export enum Scope {
  REGISTER_UPDATE_CALLBACK = 'REGISTER_UPDATE_CALLBACK',
  OPERATIONAL = 'OPERATIONAL',
  INITIALIZED = 'INITIALIZED',
  GENERIC = 'GENERIC',
  UPDATE = 'UPDATE',
  BUTTON = 'BUTTON',
  BUTTON_GROUP_RESET = 'BUTTON_GROUP_RESET',
  SEARCH = 'SEARCH',
  ROUTE_VERIFICATION = 'ROUTE_VERIFICATION',
  COUNT = 'COUNT',
}

export default interface UpdateEmitter {

  registerUpdateCallback(_registrationId: string, fn: Function, detectableElement: DetectableElementI): void;
  setLogToConsole(bool: Boolean): void;
  emit(scope?: Scope, data?: any): void;

}

/**
* use: <div id="detectableElement.elementId">
*/
export interface DetectableElementI {
  readonly elementId: string;
}
