import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

import Global         from '@/var/Global.interface';
import UiService      from '@/services/ui/UiService.interface';
import HttpMessage    from './error/HttpMessage.class';
import MessageService,
 { MessageType }      from '@/services/msg/MessageService.interface';
import { getUrl }     from './webpackUtil';

const axiosHttpService = Vue.axios.create({
  baseURL: getUrl('api'),
  timeout: 120000,
  withCredentials: true,
  headers: {
    Pragma: 'no-cache', // HTTP 1.0
    Expires: 0,         // Proxies
    'Cache-Control': [ 'no-cache', 'no-store', 'must-revalidate' ], // HTTP 1.1
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Credentials': true,
    // 'Access-Control-Allow-Methods': ['GET','HEAD','OPTIONS','POST','PUT'],
  },
  // paramsSerializer: (params) => {
  //   return queryString.stringify(params, {
  //     arrayFormat: 'repeat',
  //     skipNulls: true,
  //   });
  // },
});

axiosHttpService.interceptors.request.clear();
axiosHttpService.interceptors.request.use(
  (config) => {
    // setInfoBarStatus('connecting ...');  // push to UI
    if (config) {
      const _config = (config as any);
      if (_config.spinnerVisible !== false) {
        showSpinner();
      } else if (_config.progressVisible !== false) {
        startProgressBar();
      }
      if (_config.params === undefined && _config.filter !== undefined) {
        _config.params = _config.filter.getHttpParams();
      }
    }
    return config;
  },
  (error) => {
    addMessage(MessageType.ERROR, error); // push to UI
    console.log(error); // for debug
    Promise.reject(error);
  },
);

axiosHttpService.interceptors.response.clear();
axiosHttpService.interceptors.response.use(
  (response) => {
    const config = response.config || {};
    const res = response.data;
    const httpMessage = new HttpMessage(response);
    if (typeof res === 'string' && res.startsWith('<!')) {
      // not logged in, answer was a redirect
      // redirect to login page by throwing error
      redirectToLoginPage();
      return Promise.reject(res);
    } else if (response.status < 200 || response.status > 299) {
      // console.error('Error: ' + res.message);
      setMainSubTitle(res, config.subTitlePropName, 'error...');
      addMessages(httpMessage); // push to UI
      return Promise.reject(response);
    } else {
      if (config.subTitlePropName !== undefined) {
        setMainSubTitle(res, config.subTitlePropName);
      }
      if (config.onSuccessMessage !== undefined) {
        addMessage(MessageType.SUCCESS, '' + config.onSuccessMessage, httpMessage); // push to UI
      } else {
        hideSpinner();
        // clearSystemStatus(); // push to UI
      }
      return res;
    }
  },
  (error) => {
    const httpMessage = new HttpMessage(error);
    addMessages(httpMessage); // push to UI
    return Promise.reject(httpMessage);
  },
);

export default axiosHttpService;

// function setInfoBarStatus(s: String): void {
//   try {
//     const global: Global = Vue.prototype.$global;
//     const content: InfoBarContent = global.infoBarContent;
//     ui.setSystemStatus(s);
//   } catch(ex) {
//     console.warn('Unable to update InfoBarStatus');
//   }
// }
//
// function clearSystemStatus(): void {
//   try {
//     const global: Global = Vue.prototype.$global;
//     const content: InfoBarContent = global.infoBarContent;
//     ui.clearSystemStatus();
//     global.hideSpinner();
//     global.stopProgressBar();
//   } catch(ex) {
//     console.warn('Unable to clear InfoBarStatus');
//   }
// }

function buildMessage(message?: any): string {
  const type = typeof message;
  if (type === 'string') {
    return '' + message;
  }
  const error = message || {};
  const errorName: string = error.name;
  const errorMsg: string = ': ' + error.message;
  const config: any = error.config || {};
  const method: string = config ? ' ' + config.method : '';
  const url: string = config ? ' ' + config.url : '';
  return errorName + errorMsg + method + url;
}

function addMessage(messageType: MessageType, message?: any, httpMessage?: HttpMessage): void {
  try {
    const global: Global = Vue.prototype.$global;
    const uiService: UiService = global.ui;
    const msgService: MessageService = global.msg;
    // ui.clearSystemStatus();
    let forceHideSpinner;
    switch (messageType) {
      case MessageType.FATAL:
      case MessageType.ERROR: forceHideSpinner = true; break;
      default: break;
    }
    uiService.hideSpinner(forceHideSpinner);
    const msg = buildMessage(message);
    msgService.addMessage(messageType, msg, httpMessage);
  } catch(ex) {
    console.warn('Unable to add message');
  }
}

function addMessages(httpMessage: HttpMessage): void {
  const messageType = httpMessage.status === '400' ? MessageType.ERROR : MessageType.WARNING;
  let msg = '';
  if (httpMessage.propertyErrors.length === 0) {
    addMessage(messageType, '' + httpMessage.detail, httpMessage);
  } else {
    for (let idx = 0; idx < httpMessage.propertyErrors.length; idx++) {
      const e = httpMessage.propertyErrors[idx];
      msg = '' + e.id + ': ' + e.message;
      addMessage(messageType, msg, httpMessage);
    }
  }
}

function showSpinner(): void {
  try {
    const global: Global = Vue.prototype.$global;
    const uiService: UiService = global.ui;
    uiService.showSpinner();
  } catch(ex) {
    console.warn('Unable to show spinner');
  }
}

function hideSpinner(force?: Boolean): void {
  try {
    const global: Global = Vue.prototype.$global;
    const uiService: UiService = global.ui;
    uiService.hideSpinner();
  } catch(ex) {
    console.warn('Unable to hide spinner');
  }
}

function startProgressBar(): void {
  try {
    const global: Global = Vue.prototype.$global;
    const uiService: UiService = global.ui;
    uiService.startProgressBar();
  } catch(ex) {
    console.warn('Unable to start progress bar');
  }
}

function setMainSubTitle(body?: any, subTitlePropName?: String, errorMsg?: String): void {
  try {
    let subTitle = '';
    if (subTitlePropName !== undefined && errorMsg !== undefined) {
      subTitle = '' + errorMsg;
    } else if (subTitlePropName !== undefined && body !== undefined) {
      const propValue = getPropertyValue(body, subTitlePropName);
      subTitle = propValue === undefined ? '' : '' + propValue;
      if (propValue === undefined) {
        console.warn('setMainSubTitle > body does not contain property: ' + subTitlePropName);
      }
    }
    if (subTitle.length === 0) return;
    const global: Global = Vue.prototype.$global;
    const uiService: UiService = global.ui;
    uiService.setMainSubTitle(subTitle);
  } catch(ex) {
    console.warn('Unable to set main subtitle');
  }
}

function getPropertyValue(body?: any, subTitlePropName?: String): String | undefined {
  let propValue;
  const o: any = body || {};
  const propName = '' + subTitlePropName;
  if (propName.includes('.')) {
    const propNameParts = propName.split('.');
    const pos = propName.indexOf('.');
    const bodyPropName = propNameParts[0];
    if (bodyPropName.length === 0) return undefined;
    const bodyOfProp = o[bodyPropName];
    const remainingPropName = propName.substring(pos + 1);
    return getPropertyValue(bodyOfProp, remainingPropName);
  } else {
    propValue = o[propName];
    return propValue;
  }
  return undefined;
}

function redirectToLoginPage(): void {
  try {
    addMessage(MessageType.FATAL, 'Connection to server lost! You will be redirected to re-authenticate'); // push to UI
    const url = '' + window.location.href;
    const hashTag = '/#/';
    const pos = url.indexOf(hashTag);
    const redirect = url.substring(0, pos + hashTag.length);
    setTimeout(() => { hideSpinner(); window.location.replace(redirect); }, 3300);
  } catch(ex) {
    console.warn('Unable to redirect to login page');
  }

}
